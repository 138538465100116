import React, { ChangeEvent, KeyboardEvent } from 'react';
import { image_error_replace } from '../../../util_functions';

import './new_chat.css';
import '../../../../app/components/teacher/workshop/grp.css';
import { createState } from 'state-pool';
import StudentProfilePopUp from '../user_sch_session/StudentProfilePop';
import Picker from 'emoji-picker-react';
import { post_api } from '../../../redux/api_funcs';
import urls from '../../../urls';
import moment from 'moment';
import {
  ChatCrossIcon,
  MinimizeIcon,
  RightChevron,
  SearchIcon,
  WhatsappIcon
} from '../../../../svg';
import { SMSChannel, SMSChannelMsg, SMSChannelPkt } from 'myt-chat/src/chat/packets/SMSChannel';
import UserRole from 'myt-chat/src/chat/enums/UserRole';
import { CallbackHandler } from 'myt-chat/src/chat/callbacks/handler';
import SMSHandler from 'myt-chat/src/chat/network/SMSHandler';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import { connect } from 'react-redux';

type SmsState = {
  filteredchannels: Array<SMSChannel>;
  allChannels: Array<SMSChannel>;
  search_input_click: boolean;
  chat_student_search: string;
  chatSynced: boolean;
};

type SmsProps = {};

export class SMSList extends React.Component<SmsProps, SmsState> {
  constructor(props: SmsProps) {
    super(props);

    this.state = {
      filteredchannels: [],
      allChannels: [],
      search_input_click: false,
      chat_student_search: '',
      chatSynced: false
    };
  }

  smsEventCallback = (item: SMSChannelPkt) => {
    let channel = this.state.allChannels.find((x) => x.id === item.channel);

    if (channel === undefined) {
      channel = {
        id: item.channel,
        name: item.to.role === UserRole.STUDENT ? item.to.name : item.from.name,
        avatar: item.to.role === UserRole.STUDENT ? item.to.avatar : item.from.avatar,
        unread: 0,
        student: item.to.role === UserRole.STUDENT ? item.to.user : item.from.user,
        lastActivity: Math.round(item.timestamp / 1000),
        is_active: 1,
        domain: 'lifestyle'
      };

      this.state.allChannels.push(channel!);
    } else {
      channel.lastActivity = Math.round(item.timestamp / 1000);
    }

    let windowItem = smsWindowManagerState.value.windows.find((x) => x.channel.id === item.channel);

    if (windowItem === undefined) {
      channel.unread = channel.unread + 1;
    }

    this.state.allChannels.sort((a, b) => {
      if (b.unread > a.unread) {
        return 1;
      }
      if (a.unread > b.unread) {
        return -1;
      }
      if (b.lastActivity > a.lastActivity) {
        return 1;
      }
      if (a.lastActivity > b.lastActivity) {
        return -1;
      }
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    this.updateChannels(this.state.allChannels, this.state.chat_student_search);
  };

  componentDidMount(): void {
    // Register the SMS event handler.
    CallbackHandler.shared.registerSMSHandler(this.smsEventCallback);

    setTimeout(() => {
      SMSHandler.shared.fetchSMSGroups().then((result) => {
        this.updateChannels(result.groups, this.state.chat_student_search);
      });
    }, 1000);
  }

  componentWillUnmount(): void {
    CallbackHandler.shared.unregisterSMSHandler(this.smsEventCallback);
  }

  filterChannels = (e: ChangeEvent<HTMLInputElement>) => {
    let searchQuery = e.target.value.toLowerCase();
    this.updateChannels(this.state.allChannels, searchQuery);
  };

  updateChannels = (channels: Array<SMSChannel>, searchQuery: string) => {
    let filtered = channels.filter((channel) => {
      return channel.name.toLowerCase().includes(searchQuery);
    });

    this.setState({
      chatSynced: true,
      allChannels: channels,
      filteredchannels: filtered,
      search_input_click: searchQuery.length > 0,
      chat_student_search: searchQuery
    });
  };

  open_sms_channel = (channel: SMSChannel) => {
    let all = this.state.allChannels.map((x) => {
      if (x.id === channel.id) {
        x.unread = 0;
      }
      return x;
    });

    let filtered = this.state.filteredchannels.map((x) => {
      if (x.id === channel.id) {
        x.unread = 0;
      }
      return x;
    });

    this.setState(
      {
        allChannels: all,
        filteredchannels: filtered
      },
      () => {
        let copiedChannel = JSON.parse(JSON.stringify(channel));
        openSMSWindow(copiedChannel);
      }
    );
  };

  render_sms_whatsapp_status = (type = 'sms') => {
    return type === 'sms' ? (
      <>
        <div className="py-[1px] py-[5px] text-[#EE731B] text-center text-[10px] font-semibold leading-[14px] w-[33px] h-[16px] flex items-center justify-center rounded-[40px] bg-[#FFE9DA]">
          SMS
        </div>
      </>
    ) : (
      <>
        <WhatsappIcon />
      </>
    );
  };

  render_single_student = (channel: SMSChannel, index: number) => {
    return (
      <div
        className={`dashboard-single-chat ${channel.unread > 0 ? 'bg-lt' : ''}`}
        key={channel.id}
        onClick={() => this.open_sms_channel(channel)}
      >
        <div className="single-chat-profile-pic">
          <img src={channel.avatar} alt="pro_pho" onError={image_error_replace} />
        </div>
        <div className="single-chat-name-status flex justify-between w-full truncate ml-10px">
          <div>
            <div className="text-13px font-semibold break-all truncate">{channel.name}</div>
            {channel.domain === 'backpain' ? (
              <div className="text-8.5px font-bold leading-normal bg-backPainBadge text-white rounded-2px px-3px pt-2px w-fit">
                Back Pain Client
              </div>
            ) : null}
          </div>
          <div className="text-mytLabelRed font-bold">{channel.is_active === 0 && 'Inactive'}</div>
          <div>{this.render_sms_whatsapp_status(channel.type)}</div>
        </div>
        {channel.unread > 0 ? <div className="dashboard-chat-badge">{channel.unread}</div> : null}
      </div>
    );
  };

  searchFocus = () => {
    this.setState({
      search_input_click: true
    });
  };

  searchFocusOut = () => {
    this.setState({
      search_input_click: false
    });
  };

  render_chats = () => {
    if (!this.state.chatSynced) {
      return <span className="no_stu_found">Loading...</span>;
    }

    if (this.state.filteredchannels.length === 0) {
      return <span className="no_stu_found">No students available</span>;
    }

    const filteredchannels = this.state.filteredchannels;
    filteredchannels.sort(function (a, b) {
      return a.is_active - b.is_active;
    });

    return filteredchannels.map(this.render_single_student);
  };

  render() {
    return (
      <div className="border border-mytgrey-litev1 shadow-mytboxshadow rounded-8px w-full relative bg-white ml-0 mb-25px">
        <div className="dash-chat-header">
          <div className="text-mytgrey-grayLite font-semibold text-11px font-inter">
            SMS & WHATSAPP
          </div>
          <div
            className={`chat-search-div items-center relative flex w-min ${this.state.search_input_click ? 'full-w' : ''}`}
            onMouseEnter={this.searchFocus}
            onMouseLeave={this.searchFocusOut}
          >
            <div className="ch-sea-ic">{<SearchIcon />}</div>
            <input
              className={`${this.state.search_input_click ? 'bg-mytgrey-card rounded-7px py-10px pl-15px pr-30px' : ''}`}
              placeholder={this.state.search_input_click ? 'Search for students' : 'search'}
              onFocus={this.searchFocus}
              onChange={this.filterChannels}
              value={this.state.chat_student_search}
            />
          </div>
        </div>

        <div className="dashboard-chat-chats scrollYogaTypes"> {this.render_chats()}</div>
      </div>
    );
  }
}

export enum SMSOpenState {
  CLOSED = 0,
  MINIMIZED = 1,
  OPEN = 2
}

type SMSWindowItem = {
  channel: SMSChannel;
  openState: SMSOpenState;
};

export type ChatWindowManagerGlobalState = {
  windows: Array<SMSWindowItem>;
};

function createDefaultState(): ChatWindowManagerGlobalState {
  return {
    windows: new Array<SMSWindowItem>()
  };
}

export const smsWindowManagerState = createState(createDefaultState()); // Global state for chat windows

function openSMSWindow(channelItem: SMSChannel) {
  smsWindowManagerState.updateValue(
    (oldState: ChatWindowManagerGlobalState): ChatWindowManagerGlobalState => {
      let windowItemIndex = oldState.windows.findIndex(
        (item) => item.channel.id === channelItem.id
      );

      if (windowItemIndex === -1) {
        let item = {
          channel: channelItem,
          openState: SMSOpenState.OPEN
        };
        oldState.windows.unshift(item);
        return oldState;
      } else {
        let item = oldState.windows[windowItemIndex];
        item.openState = SMSOpenState.OPEN;
        oldState.windows.splice(windowItemIndex, 1);
        oldState.windows.unshift(item);
        return oldState;
      }
    }
  );
}

type SMSWindowManagerState = {
  chat_count: number;
  windows: Array<SMSWindowItem>;
};

type SMSWindowManagerProps = {};

export class SMSWindowManager extends React.Component<
  SMSWindowManagerProps,
  SMSWindowManagerState
> {
  constructor(props: SMSWindowManagerProps) {
    super(props);
    this.state = {
      chat_count: Math.floor(window.innerWidth / 390),
      windows: []
    };
  }

  subscription: undefined | (() => void);

  componentDidMount(): void {
    this.subscription = smsWindowManagerState.subscribe(
      (newValue: ChatWindowManagerGlobalState) => {
        this.setState({
          windows: newValue.windows
        });
      }
    );
  }

  componentWillUnmount() {
    this.subscription?.();
  }

  render_chat_windows = () => {
    let displayable = Math.max(this.state.windows.length - this.state.chat_count, 0);
    const slice = this.state.windows.slice(displayable);
    return slice.map((item) => {
      return <SMSWindowWithRedux channel={item.channel} key={item.channel.id} />;
    });
  };

  render() {
    return <div className="ch-cws">{this.render_chat_windows()}</div>;
  }
}

type StudentDetails = {
  uuid: string;
  goals: string;
  yoga_level: string;
  yoga_types_practiced: string;
  years_of_experience: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  full_name: string;
  profile_photo: string;
  profile_photo_thumbnail: string;
  device: string;
  iana_timezone: string;
  number_of_sessions_finished: number;
  number_of_sessions_scheduled: number;
  medical_history: number;
  medical_history_details: string;
  health_history: string;
  membership_plan: string;
  twilio_channel_sid: string;
  student_member_sid: string;
  teacher_member_sid: string;
  student_identity: string;
  teacher_identity: string;
  show_yoga_journey: number;
  is_roadmap_present: number;
  slug: string;
  age: number;
  gender: string;
  client_domain: string;
  is_trial: number;
  trial_end_date: string;
  is_glean_customer: number;
  student_additional_info: string;
  first_one_on_one_session_notes: any[];
  concierge_name: string;
  health_history_list: string[];
};

type SMSWindowProps = {
  channel: SMSChannel;
  student_details: StudentDetails;
  load_student_details: ({ student_uuid }: { student_uuid: string }) => void;
};

type SMSWindowState = {
  channel: SMSChannel;
  messages: Array<SMSChannelMsg>;
  openState: SMSOpenState;
  view_profile: boolean;
  open_p_uuid: string;
  sms_text_data: string;
  input_focus: boolean;
  current_chat_focus: boolean;
  showScrollBottom: boolean;
  pickEmoji: boolean;
  is_loading: boolean;
  is_disabled: boolean;
  timeLeft: string;
  is_whatsapp: boolean;
};

export class SMSWindow extends React.Component<SMSWindowProps, SMSWindowState> {
  messageEnd = React.createRef<HTMLDivElement>();
  smsWindowManagerSubscription: (() => void) | undefined;
  timerInterval: NodeJS.Timeout | null = null;

  constructor(props: SMSWindowProps) {
    super(props);

    this.state = {
      channel: props.channel,
      openState: SMSOpenState.OPEN,
      messages: [],
      view_profile: false,
      open_p_uuid: '',
      sms_text_data: '',
      input_focus: false,
      current_chat_focus: false,
      showScrollBottom: false,
      pickEmoji: false,
      is_loading: true,
      is_disabled: false,
      timeLeft: '24:00:00',
      is_whatsapp: props.channel.type === 'whatsapp' || false
    };
  }

  smsCallback = (item: SMSChannelPkt) => {
    const message: SMSChannelMsg = {
      id: item.id,
      text: item.text,
      media: item.media,
      timestamp: item.timestamp,
      toStudent: item.to.role === UserRole.STUDENT,
      profile: item.from,
      type: item.from.type
    };

    this.state.messages.push(message);
    this.state.messages.sort((a, b) => a.timestamp - b.timestamp);

    this.setState({ messages: this.state.messages }, () => {
      this.scrollToBottom();
      // Restart the timer with the updated messages array.
      this?.props?.channel?.type === 'whatsapp' && this.isAnyMessageSentByStudent()
        ? this.startTimer()
        : null;
    });
  };

  componentDidMount() {
    const payload = {
      student_uuid: this.state.channel.student
    };
    this.props.load_student_details(payload);

    // Fetch SMS archive and then start the timer.
    SMSHandler.shared.fetchSMSArchive(this.state.channel.student).then((result) => {
      this.setState(
        {
          is_loading: false,
          messages: result.messages
        },
        () => {
          this?.props?.channel?.type === 'whatsapp' && this.isAnyMessageSentByStudent()
            ? this.startTimer()
            : null;
        }
      );
    });

    this.smsWindowManagerSubscription = smsWindowManagerState.subscribe(
      (global: ChatWindowManagerGlobalState) => {
        global.windows.forEach((item) => {
          if (
            item.channel.id === this.state.channel.id &&
            this.state.openState !== item.openState
          ) {
            this.setState({
              openState: item.openState
            });
          }
        });
      }
    );

    CallbackHandler.shared.registerSMSHandler(this.smsCallback);
    this?.props?.channel?.type === 'whatsapp' &&
      this.isAnyMessageSentByStudent() &&
      this.startTimer();
  }

  componentWillUnmount() {
    CallbackHandler.shared.unregisterSMSHandler(this.smsCallback);
    this.smsWindowManagerSubscription?.();
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  on_close_click = () => {
    this.setState(
      {
        openState: SMSOpenState.CLOSED
      },
      () => {
        smsWindowManagerState.updateValue((state: ChatWindowManagerGlobalState) => {
          let itemIndex = state.windows.findIndex(
            (item) => item.channel.id === this.state.channel.id
          );
          if (itemIndex !== -1) {
            state.windows.splice(itemIndex, 1);
          }
          return state;
        });
      }
    );
  };

  on_minimize_toggle_click = () => {
    let nextState: SMSOpenState;
    nextState =
      this.state.openState === SMSOpenState.OPEN ? SMSOpenState.MINIMIZED : SMSOpenState.OPEN;

    this.setState(
      {
        openState: nextState
      },
      () => {
        smsWindowManagerState.updateValue((state: ChatWindowManagerGlobalState) => {
          let itemIndex = state.windows.findIndex(
            (item) => item.channel.id === this.state.channel.id
          );
          if (itemIndex !== -1) {
            state.windows[itemIndex].openState = nextState;
          }
          return state;
        });
      }
    );
  };

  reply_user = async (isWhatsapp = false) => {
    const url = isWhatsapp ? urls.send_whatsapp : urls.send_sms;
    const currentSMSData = this.state.sms_text_data;
    this.setState({ is_disabled: true });
    let payload: {
      to_student_uuid?: string;
      sms_template_tag?: string;
      message_medium_type?: string;
      message?: string;
      body?: string;
      timeline_event?: string;
    } = {};
    try {
      if (isWhatsapp) {
        payload = {
          to_student_uuid: this.state.channel.student,
          sms_template_tag: 'predefined_message_for_coach',
          message_medium_type: 'WHATSAPP'
        };
        if (this.state.timeLeft !== '00:00:00') {
          payload.message = this.state.sms_text_data;
        }
      } else {
        payload = {
          to_student_uuid: this.state.channel.student,
          body: this.state.sms_text_data,
          timeline_event: 'sms_teacher_web_app'
        };
      }
      const response = await post_api(url, payload, true);
      this.setState({ sms_text_data: '' });
      if (response.data.status === 'success') {
        this.setState({
          is_disabled: false
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        is_disabled: false,
        sms_text_data: currentSMSData
      });
    }
  };

  scrollToBottom = () => {
    try {
      const height = this.messageEnd.current?.scrollHeight || 0;
      this.messageEnd.current?.scrollTo(0, height);
    } catch (e) {
      console.log(e);
    }
  };

  render_sms = (sms: SMSChannelMsg, index: number) => {
    return (
      <div className={sms.toStudent ? 'my-sms' : 'friend-sms'} key={sms.id}>
        <div
          className="chat-cmt-box"
          style={{
            backgroundColor: sms.profile.role === 'teacher' ? '#DFFFF9' : null
          }}
        >
          <span>{sms.text}</span>
          <br />
          <div className={sms.toStudent ? 'sms-time chat-me' : 'sms-time'}>
            <div>{sms.profile.name}</div>
          </div>
        </div>
        <div>
          <div className={sms.toStudent ? 'sms-time chat-me' : 'sms-time'}>
            <div className="flex mb-[15px] justify-between">
              <div className="text-[12px] text-[#787878] leading-[18px]">
                {moment(sms.timestamp).format('YYYY-MM-DD HH:mm:ss')}
              </div>
              {this.props?.channel?.type === 'whatsapp' && (
                <div className="flex gap-[7px]">
                  {sms?.type === 'whatsapp' ? (
                    <>
                      {' '}
                      <div className="text-[10px] text-[#25D366] font-semibold leading-[18px]">
                        WhatsApp Chat
                      </div>
                      <div>
                        <WhatsappIcon />
                      </div>
                    </>
                  ) : (
                    <div className="py-[1px] py-[5px] text-[#EE731B] text-center text-[10px] font-semibold leading-[14px] w-[33px] h-[16px] flex items-center justify-center rounded-[40px] bg-[#FFE9DA]">
                      SMS
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  openProfile = () => {
    if (this.props?.student_details?.slug) {
      window.open(`/student-profile/${this.props.student_details.slug}`, '_blank');
    }
  };

  closeProfile = () => {
    this.setState({
      view_profile: false,
      open_p_uuid: ''
    });
  };

  onEmojiClick = (e, emoji) => {
    this.setState({
      sms_text_data: this.state.sms_text_data + emoji.emoji,
      pickEmoji: false
    });
    document.getElementById(`text-area-${this.state.channel.id}`)?.focus();
  };

  closeEmoji = () => {
    this.setState({
      pickEmoji: false
    });
  };

  render_chat_header = () => {
    const header_class = this.state.input_focus ? 'c-c-header' : 'c-c-header c-c-header-o';
    const img_class = this.state.input_focus ? '' : 'c-i-o';

    return (
      <div className={`${header_class} pt-[14px] pb-[15px] pl-5 pr-[15px] `}>
        <div className="c-c-header-c">
          <div className="chat-tooltip-ic open-profile">View Profile</div>
          <div className={`ch-sender-name desk-chat-p`}>
            <div className="flex items-center gap-2.5">
              <div
                className="h-[30px] w-[30px] relative object-cover"
                onClick={() => {
                  if (this.state.channel.domain === 'backpain') {
                    const { student } = this.state.channel;
                    window.open(`/backpain-client-profile?id=${student}`, '_blank');
                  } else {
                    this.openProfile();
                  }
                }}
              >
                <img
                  className="w-7 h-7 object-cover"
                  src={this.state.channel.avatar}
                  style={{ borderRadius: '50%' }}
                  onError={image_error_replace}
                />
                <p className="absolute top-0 right-0 p-1 rounded-full bg-[#93C8AF]"></p>
              </div>
              <div className="">
                <b
                  className="ch_name_desk"
                  onClick={() => {
                    if (this.state.channel.domain === 'backpain') {
                      const { student } = this.state.channel;
                      window.open(`/backpain-client-profile?id=${student}`, '_blank');
                    } else {
                      this.openProfile();
                    }
                  }}
                >
                  <div className="flex gap-[5px] items-center">
                    SMS: {this.state.channel.name.substring(0, 18)}
                    <RightChevron />
                  </div>
                </b>
                <span className="ch_status_text">{'Online'}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="c-c-header-i">
          <button className="chat-c-btn" onClick={this.on_minimize_toggle_click}>
            {this.state.openState === SMSOpenState.OPEN ? (
              <div className="chat-tooltip-ic">Minimize tab</div>
            ) : (
              <div className="chat-tooltip-ic">Expand tab</div>
            )}
            {this.state.openState === SMSOpenState.OPEN ? (
              <MinimizeIcon />
            ) : (
              <img
                className={img_class + ' c-c-expand'}
                alt="expand"
                src="https://images.myyogateacher.com/ic_expand.svg"
              />
            )}
          </button>
          <button className="chat-c-btn last-btn" onClick={this.on_close_click}>
            <div className="chat-tooltip-ic">Close tab</div>
            {/* <img
              className="close_min_chat"
              src="https://images.myyogateacher.com/ic_cross_ch1.svg"
              alt="c"
            /> */}
            <ChatCrossIcon />
          </button>
        </div>
      </div>
    );
  };

  onSmsTextDataChanged = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      sms_text_data: e.target.value
    });
  };

  on_enter_press = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (this.state.input_focus && event.key === 'Enter' && event.shiftKey) {
      this.setState({
        sms_text_data: event.currentTarget.value + '\n'
      });
      return;
    }
    if (this.state.input_focus && event.key === 'Enter') {
      event.preventDefault();
      this.reply_user();
    }
  };

  onInputBlur = () => this.setState({ input_focus: false });
  onInputFocus = () => this.setState({ input_focus: true });

  render_chat_loading = () => (
    <div className="c-c-loading">
      <div />
    </div>
  );

  render_chat_content = () => {
    return (
      <>
        {this?.props?.channel?.type === 'whatsapp' &&
        this.state.timeLeft !== '00:00:00' &&
        this.isAnyMessageSentByStudent()
          ? this.render_timer()
          : null}
        <div className="c-c-messages" id="chat-window" ref={this.messageEnd}>
          {this.state.messages.map((message, index) => {
            return this.render_sms(message, index);
          })}
          {this.state.showScrollBottom && (
            <div
              onClick={(event) => {
                this.scrollToBottom();
              }}
              className="chat-scroll-to"
            >
              <img alt="send" src="https://images.myyogateacher.com/icons/ic_ch_right.svg" />
            </div>
          )}
        </div>
      </>
    );
  };
  isFirstMessSentByUs = () => {
    const messages = this.state.messages || [];
    return messages.some((message) => message.toStudent === true);
  };
  isAnyMessageSentByStudent = () => {
    const messages = this.state.messages || [];
    if (messages.length === 0) return false; // No messages available
    return messages.some((mess) => mess.toStudent === false && mess.type === 'whatsapp');
  };
  isLastMessageSentByStudent = () => {
    const messages = this.state.messages || [];
    if (messages.length === 0) return false; // No messages available

    // Get the last message (most recent)
    const lastMessage = messages[messages.length - 1];
    return lastMessage.toStudent === false;
  };
  // startTimer calculates the remaining time (24 hours from the last message timestamp)
  startTimer = () => {
    // Clear any previous interval to avoid multiple timers running
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    const messages = this.state.messages;

    let lastMessageIndex = [...this.state.messages]
      .reverse()
      .findIndex((ele) => !ele.toStudent && ele.type === 'whatsapp');
    lastMessageIndex =
      lastMessageIndex >= 0 ? this.state.messages.length - 1 - lastMessageIndex : -1;

    const lastMessageTimestamp =
      this.state.messages.length > 0 &&
      lastMessageIndex >= 0 &&
      Object.keys(messages[lastMessageIndex]).length > 0 &&
      messages[lastMessageIndex].timestamp
        ? messages[lastMessageIndex].timestamp
        : Date.now();
    const expirationTime = lastMessageTimestamp + 24 * 60 * 60 * 1000; // 24 hours later

    const updateTimer = () => {
      const currentTime = Date.now();
      const remainingTime = expirationTime - currentTime;

      if (remainingTime <= 0) {
        this.setState({ timeLeft: '00:00:00' });
        if (this.timerInterval) clearInterval(this.timerInterval);
        return;
      }

      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      this.setState({
        timeLeft: `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
      });
    };

    // Immediately update the timer and then every second
    updateTimer();
    this.timerInterval = setInterval(updateTimer, 1000);
  };

  render_timer = () => {
    return (
      <div>
        <div className="border-t border-b border-[#FFDCDC] bg-[#FFF0F0] px-[15px] py-[14px]">
          <div className="text-[#FF3131] text-center font-inter text-[13px] font-medium leading-[14px]">
            Time left to reply {this.state.timeLeft}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { is_disabled } = this.state;
    if (this.state.openState === SMSOpenState.CLOSED) {
      return <></>;
    }
    if (this.state.openState === SMSOpenState.MINIMIZED) {
      return (
        <div className="c-c-window-min ch_new">
          {this.render_chat_header()}
          {this.state.view_profile ? (
            <StudentProfilePopUp
              closeFunc={this.closeProfile}
              student_uuid={this.state.open_p_uuid}
            />
          ) : null}
        </div>
      );
    }
    return (
      <div
        className={`c-c-window ch_new ${this.state.current_chat_focus ? 'cur_chat_focus' : ''}`}
        onFocus={() => {
          this.setState({
            current_chat_focus: true
          });
        }}
        onBlur={() => {
          this.setState({
            current_chat_focus: false
          });
        }}
        onScroll={(event: any) => {
          if (
            event.target.scrollTop + event.target.offsetHeight <
            event.target.scrollHeight - event.target.offsetHeight
          ) {
            this.setState({
              showScrollBottom: true
            });
          } else {
            this.setState({
              showScrollBottom: false
            });
          }
        }}
      >
        {this.render_chat_header()}
        {this.state.pickEmoji && (
          <div className="emoji-cont">
            <div className="emoji-head"></div>
            <Picker onEmojiClick={this.onEmojiClick} />
          </div>
        )}
        {this.state.is_loading ? (
          this.render_chat_loading()
        ) : (
          <div className="c-c-content">
            {this.render_chat_content()}
            <div className="c-c-i-c">
              <div className="chat-tooltip">
                Hit <b>Enter</b> to send message
              </div>
              {!this.state.is_whatsapp ||
              (this.state.timeLeft !== '00:00:00' && this.isAnyMessageSentByStudent()) ? (
                <div className="textarea-cont">
                  <textarea
                    id={`text-area-${this.state.channel.id}`}
                    className="textarea chat-textarea"
                    value={this.state.sms_text_data}
                    onChange={this.onSmsTextDataChanged}
                    autoFocus
                    onKeyPress={this.on_enter_press}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    placeholder={'Please reply here...'}
                  />
                  {this.state.sms_text_data.length > 0 && (
                    <span
                      onClick={() => this.reply_user()}
                      className={`send-btn-c ${is_disabled ? 'opacity-60 pointer-events-none' : ''}`}
                    >
                      Send
                    </span>
                  )}
                </div>
              ) : (
                <div
                  className={`btn btn-primary cursor-pointer w-full text-13.5px h-38px font-semibold  ${is_disabled ? 'opacity-60 pointer-events-none' : ''}`}
                  onClick={() => this.reply_user(true)}
                >
                  Send predefined message
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.view_profile ? (
          <StudentProfilePopUp
            closeFunc={this.closeProfile}
            student_uuid={this.state.open_p_uuid}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    student_details: selectors.get_student_details(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load_student_details: (payload) => {
      dispatch(actions.load_student_details(payload));
    }
  };
};

const SMSWindowWithRedux = connect(mapStateToProps, mapDispatchToProps)(SMSWindow);
